import { FC, useEffect, useState } from 'react';
import { SortDirection, sortExpression } from '../../../utils/ListUtils';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';

type SortableHeadingProps = {
  title: string;
  expression: string;
  onSort: (fieldName: string, expression: string) => void;
};

const SortableHeading: FC<SortableHeadingProps> = (props) => {
  const { title, expression, onSort } = props;
  const [sortDirection, setSortDirection] = useState(SortDirection.Asc);

  useEffect(() => {
    setSortDirection(expression?.charAt(0) === '-' ? SortDirection.Desc : SortDirection.Asc);
  }, [expression]);

  const toggleDirection = () => {
    const newDirection = sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    setSortDirection(newDirection);

    const fieldName = expression?.replace(/[-+]/g, '');
    onSort(fieldName, sortExpression(fieldName, newDirection));
  };

  return (
    <div onClick={toggleDirection} className="flex cursor-pointer items-center">
      <span>{title}</span> <ChevronIcon type={sortDirection === SortDirection.Desc ? ChevronType.DOWN : ChevronType.UP} className="ml-1 h-5 w-5" />
    </div>
  );
};

export default SortableHeading;
